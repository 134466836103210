html,
body {
  @apply h-full;
}

body {
  @apply bg-neutral-100;
  @apply text-neutral-900;
}

body.dark {
  @apply bg-neutral-900;
  @apply text-neutral-50;
}
.mat-icon
{
  color: #8e9cb3;
}
span.mat-list-text {
  color: #222b45;
}
.mat-drawer-content {
  background: #edf1f7;
}