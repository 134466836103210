/* base */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import './tailwindcss/base.scss';
@import './01-base/color.scss';
@import './01-base/document.scss';
@import './01-base/font.scss';
@import './01-base/heading.scss';

/* components */
@import './tailwindcss/components.scss';
@import './02-components/link.scss';
@import './02-components/button.scss';
@import './02-components/container.scss';
@import './02-components/input.scss';

/* utilities */
@import './tailwindcss/utilities.scss';

html,
body {
  height: 100%;
  // overflow: hidden;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track-piece {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: #999999;
}

.common-back-header {
  text-align: left;
  color: #030384;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  mat-icon {
    color: #030384;
    cursor: pointer;
  }
  h3 {
    margin: 0 0 0 15px;
  }
}

// Tab
mat-tab-group.mat-tab-group {
  .mat-tab-label {
    font-size: 18px;

    &.mat-tab-label-active {
      color: #ff4081;
      font-weight: 500;
    }
  }

  .mat-tab-body-wrapper {
    padding-top: 0px;
  }
}
